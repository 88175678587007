/* make color picker responsive */
#main-content .react-colorful,
#headlessui-portal-root .react-colorful {
  width: 100%;
}

/* https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.prose-theleap {
  margin: 0.5rem 0;
}

/* fix spacing for list */
/* fix list spacing, if list is first item and will shift rich edit */
.prose-theleap ul li > p {
  margin: 0;
}

.prose-theleap ol li > p {
  margin: 0;
}

.prose-theleap li {
  margin-top: 0;
}

/* fix h3 size */
.prose-theleap h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

/* specific styles onboarding value ladder */
.arrow-line::after {
  width: calc(100% - 23px);
}

.mobile-line {
  height: calc(100% - 3rem);
}

.final-shadow {
  box-shadow: 0px 0px 0px 12px rgba(245, 247, 160, 0.5);
}
/* END specific styles onboarding value ladder */

.previous-card-button {
  position: fixed;
  right: calc(100% + 0.75rem);
  left: auto;
  padding-right: 0.125rem;
}

.previous-card-button svg {
  margin-right: 0;
}

.next-card-button {
  position: fixed;
  left: calc(100% + 0.75rem);
  right: auto;
  z-index: 20;
  padding-left: 0.125rem;
}

.next-card-button svg {
  margin-left: 0;
}

.story-player.is-building .next-card-button,
.story-player.is-building .previous-card-button {
  display: none;
}

/* Fix for a bug in Safari where position fixed elements
get cut off when parent has overflow hidden: https://bugs.webkit.org/show_bug.cgi?id=160953 */
.story-player .swiper:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: -3.5rem;
  right: -3.5rem;
  z-index: 2;
  pointer-events: none;
}

.error-page-background-image {
  background-image: url('/animations/wave.gif');
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  bottom: 0;
  background-size: 100% 20%;
}
